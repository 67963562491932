<template>
  <div class="inner-section application-form-wrapper">
    <bread-cumb />
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGarden.task_assign') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-overlay :show="loading">
            <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
              <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Garden Name" vid="garden_id" rules="required|min_value:1">
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label-for="garden_id">
                      <template v-slot:label>
                        {{ $t('teaGardenConfig.select_garden') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        :disabled="isGardenAdmin"
                        plain
                        v-model="formData.garden_id"
                        :options="masterTeaGardenGenInfoList"
                        id="garden_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Task" vid="task_id" rules="required|min_value:1">
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label-for="task_id">
                      <template v-slot:label>
                        {{ $t('teaGarden.task_name') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="task_id"
                        :options="gdnTaskList"
                        v-model="formData.task_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                 <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Description (En)" vid="description_en">
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label-for="description_en">
                      <template v-slot:label>
                        {{ $t('teaGardenConfig.description') }} {{ $t('globalTrans.en') }}
                      </template>
                      <b-form-textarea
                        id="description_en"
                        v-model="formData.description_en"
                        :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-textarea>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                 <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Description (Bn)" vid="description_bn">
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label-for="description_bn">
                      <template v-slot:label>
                        {{ $t('teaGardenConfig.description') }} {{ $t('globalTrans.bn') }}
                      </template>
                      <b-form-textarea
                        id="description_bn"
                        v-model="formData.description_bn"
                        :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-textarea>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="6">
                  <ValidationProvider name="Start Date" vid="start_date" rules="required">
                    <b-form-group
                      class="row"
                      label-for="start_date"
                      slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('globalTrans.start_date') }}  <span class="text-danger">*</span>
                      </template>
                      <date-picker
                        id="start_date"
                        v-model="formData.start_date"
                        class="form-control"
                        :placeholder="$t('globalTrans.select_date')"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :class="errors[0] ? 'is-invalid' : ''"
                        :locale="currentLocale"
                      >
                      </date-picker>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="6">
                  <ValidationProvider name="End Date" vid="end_date" rules="required">
                    <b-form-group
                      class="row"
                      label-for="end_date"
                      slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('globalTrans.end_date') }}  <span class="text-danger">*</span>
                      </template>
                      <date-picker
                        id="end_date"
                        v-model="formData.end_date"
                        class="form-control"
                        :placeholder="$t('globalTrans.select_date')"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :class="errors[0] ? 'is-invalid' : ''"
                        :locale="currentLocale"
                      >
                      </date-picker>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-row v-if="taskUnitId">
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Target" vid="target" rules="required">
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label-for="target">
                      <template v-slot:label>
                        {{ $t('teaGarden.target') }} <span class="text-danger">*</span>
                      </template>
                     <b-form-input
                        v-model="formData.target"
                        id="target"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Unit Name" vid="unit_id">
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label-for="unit_id">
                      <template v-slot:label>
                        {{ $t('teaGarden.unit') }}
                      </template>
                      <b-form-select
                        plain
                        v-model="taskUnitId"
                        :options="unitList"
                        id="unit_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Working Days" vid="working_days" rules="required|min_value:1">
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label-for="working_days">
                      <template v-slot:label>
                        {{ $t('teaGarden.working_days') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        v-model="formData.working_days"
                        id="target"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Total Target" vid="working_day_target" rules="required|min_value:1">
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label-for="working_day_target">
                      <template v-slot:label>
                        {{ $t('teaGarden.total_target') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        v-model="formData.working_day_target"
                        id="target"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <body-card>
                <!-- search section start -->
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{ $t('teaGardenConfig.worker_information') }}</h4>
                </template>
                <template v-slot:body>
                  <b-row>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-form-group label-for="worker_type">
                        <template v-slot:label>
                          {{ $t('teaGardenConfig.worker_type') }}
                        </template>
                        <b-form-select plain
                                       v-model="search.worker_type"
                                       :options="customWorkerTypeList"
                                       id="worker_type"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <ValidationProvider name="designation" vid="designation_id" rules="">
                        <b-form-group slot-scope="{ valid, errors }" label-for="designation_id">
                          <template v-slot:label>
                            {{ $t('teaGardenConfig.designation') }}
                          </template>
                          <b-form-select plain v-model="search.designation_id" :options="designationList" id="designation_id"
                                         :state="errors[0] ? false : (valid ? true : null)">
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <ValidationProvider name="Gender" vid="gender" rules="">
                        <b-form-group slot-scope="{ valid, errors }" label-for="gender">
                          <template v-slot:label>
                            {{ $t('teaGardenConfig.gender') }}
                          </template>
                          <b-form-select plain v-model="search.gender" :options="genderList" id="gender"
                                         :state="errors[0] ? false : (valid ? true : null)">
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-form-group label-for="mobile">
                        <template v-slot:label>
                          {{  $t('teaGardenConfig.worker_name') }}/{{ $t('teaGardenConfig.pf_id') }}
                        </template>
                        <b-form-input id="mobile"
                                      v-model="search.name_pf"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="4">
                      <b-button size="sm" variant="primary" class="mt-20" @click.prevent="searchData">
                        <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                      </b-button>
                    </b-col>
                  </b-row>
                </template>
                <!-- search section end -->
              </body-card>
              <b-overlay :show="workerLoading">
                <b-table-simple resonsive bordered class="mt-3 mb-0">
                  <b-thead>
                    <b-tr>
                      <b-th width="10%">{{ $t('globalTrans.sl_no') }}</b-th>
                      <b-th>{{ $t('teaGardenConfig.worker_name') }}</b-th>
                      <b-th>{{ $t('teaGardenConfig.pf_id') }}</b-th>
                      <b-th>{{ $t('teaGardenConfig.worker_type') }}</b-th>
                      <b-th>{{ $t('globalTrans.designation') }}</b-th>
                      <b-th>{{ $t('globalTrans.mobile') }}</b-th>
                      <b-th>{{ $t('globalTrans.gender') }}</b-th>
                      <b-th width="10%">
                        <b-form-checkbox
                          id="checkbox-all"
                          v-model="checkAll"
                          name="checkbox-all"
                        >
                          {{ $t('globalTrans.select') }}
                        </b-form-checkbox>
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <template v-if="formData.worker_info.length">
                      <b-tr v-for="(item, index) in formData.worker_info" :key="index">
                        <b-td>{{ $n(index + 1) }}</b-td>
                        <b-td>{{ currentLocale === 'en' ? item.worker_name_en : item.worker_name_bn }}</b-td>
                        <b-td>{{ item.pf_id | numberConvert }}</b-td>
                        <b-td>{{ getWorkerType(item.worker_type) }}</b-td>
                        <b-td>{{ getDesignation(item.designation_id) }}</b-td>
                        <b-td>{{ item.mobile | numberConvert }}</b-td>
                        <b-td>{{ getGender(item.gender) }}</b-td>
                        <b-td>
                          <b-form-checkbox
                            v-model.number="item.is_checked"
                            name="checkbox-1"
                            value="1"
                            unchecked-value="0"
                          >
                          </b-form-checkbox>
                        </b-td>
                      </b-tr>
                    </template>
                    <template v-else>
                      <b-tr>
                        <b-td colspan="8" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                      </b-tr>
                    </template>
                  </b-tbody>
                </b-table-simple>
              </b-overlay>
              <b-row class="text-right mt-3">
                <b-col>
                    <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                    <b-button variant="danger" class="btn-sm" @click="back">{{ $t('globalTrans.cancel') }}</b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-overlay>
        </ValidationObserver>
      </template>
    </body-card>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { taskAssignWorkerList, taskAssignShow, taskAssignStore, taskAssignUpdate } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'
import BreadCumb from '@/components/BreadCumb.vue'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
  mixins: [teaGardenService],
  name: 'Form',
  components: { BreadCumb },
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      formData: {
        garden_id: 0,
        task_id: 0,
        target: '',
        start_date: '',
        end_date: '',
        description_en: '',
        description_bn: '',
        working_days: '',
        working_day_target: '',
        worker_info: []
      },
      search: {
        worker_type: 0,
        designation_id: 0,
        gender: 0,
        mobile: '',
        name_pf: ''
      },
      checkAll: false,
      workerLoading: false,
      isGardenAdmin: false,
      taskUnitId: 0
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    masterTeaGardenGenInfoList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList
    },
    gdnTaskList () {
      return this.$store.state.TeaGardenService.commonObj.gdnTaskList
    },
    currentLocale () {
      return this.$i18n.locale
    },
    customWorkerTypeList: function () {
      return this.$store.state.TeaGardenService.commonObj.workerType
    },
    designationList: function () {
      return this.$store.state.TeaGardenService.commonObj.masterDesignationList.filter(item => item.employee_type === 2)
    },
    genderList: function () {
      return this.$store.state.TeaGardenService.commonObj.gender
    },
    unitList () {
      return this.$store.state.TeaGardenService.commonObj.unitList
    }
  },
  watch: {
    'formData.task_id': function (newVal) {
      this.taskUnitId = 0
      if (newVal) {
        const obj = this.gdnTaskList.find(item => item.value === parseInt(newVal))
        if (obj !== undefined && obj.unit_id) {
          this.taskUnitId = obj.unit_id
        }
      }
    },
    'formData.target': function (newVal) {
      this.formData.working_day_target = 0
      if (newVal) {
        this.formData.working_day_target = this.formData.working_days * newVal
      }
    },
    'formData.working_days': function (newVal) {
      this.formData.working_day_target = 0
      if (newVal) {
        this.formData.working_day_target = this.formData.target * newVal
      }
    },
    checkAll: function (newVal) {
      if (newVal) {
        this.formData.worker_info.map(item => {
          item.is_checked = 1
          return item
        })
      } else {
        this.formData.worker_info.map(item => {
          item.is_checked = 0
          return item
        })
      }
    }
  },
  created () {
    if (this.$route.query.id) {
      this.getFormData(this.$route.query.id)
    }
    if (this.isGardenAdminCheckGardenId()) {
      this.isGardenAdmin = true
      this.formData.garden_id = this.isGardenAdminCheckGardenId()
    }
  },
  methods: {
    async searchData () {
      if (!this.formData.garden_id) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'Please select any garden',
          color: '#D6E09B'
        })
      }
      this.workerLoading = true
      this.formData.worker_info = []
      const params = Object.assign({}, this.search, {
        garden_id: this.formData.garden_id,
        task_id: this.formData.task_id,
        start_date: this.formData.start_date,
        end_date: this.formData.end_date,
        task_assign_id: this.$route.query.id
      })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, taskAssignWorkerList, params)
      if (result.success) {
        this.formData.worker_info = result.data
      } else {
        this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: result.message,
          color: '#D6E09B'
        })
      }
      this.workerLoading = false
    },
    async saveUpdate () {
      const checkedItems = this.formData.worker_info.filter(item => item.is_checked === 1)
      if (!checkedItems.length) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'Please check at least one worker'
        })
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.formData.id) {
        result = await RestApi.putData(teaGardenServiceBaseUrl, `${taskAssignUpdate}/${this.formData.id}`, this.formData)
      } else {
        result = await RestApi.postData(teaGardenServiceBaseUrl, taskAssignStore, this.formData)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$router.push('task-assign')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    isNumber (evt) {
        helpers.isNumber(evt)
    },
    async getFormData (id) {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, taskAssignShow + '/' + id)
      if (result.success) {
        this.formData = Object.assign({}, this.formData, result.data)
        this.$store.dispatch('mutateCommonProperties', { loading: false })
        this.searchData()
      } else {
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      }
    },
    back () {
      this.$router.go(-1)
    },
    getWorkerType (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.workerType.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getDesignation (id) {
      const obj = this.designationList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getGender (id) {
      const obj = this.genderList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    }
  }
}
</script>
